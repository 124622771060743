<template>
  <section class="h-100 flex-column d-flex align-items-center justify-content-center py-2">
    <font-awesome-icon icon="thumbs-up" class="mb-2" size="6x" />
    <p class="mb-3">{{ $t('registration.thanks') }}</p>

    <b-button @click="finalizeAccount()">
      {{ $t('registration.finalize') }}
    </b-button>
  </section>
</template>

<script>
import { patchRegisterSelectOfferRequest } from '@/request/authApi/requests/apiRequests'
import { boolean } from 'vue-mc/validation'

export default {
  name: 'Thanks',
  props: {
    selectedOfferId: {
      type: Number,
      required: true,
    },
    redirectToLogin: {
      type: boolean,
      required: true,
      default: false,
    },
    setupIntentId: {
      type: String,
      required: false,
    },
  },
  methods: {
    finalizeAccount() {
      const selectOfferObj = {
        offerId: this.selectedOfferId,
        setupIntentId: this.setupIntentId,
        redirectUrl: `${window.location.origin}/registration-user`,
        paymentUrl: `${window.location.origin}/order/#quoteId#/show`,
      }

      patchRegisterSelectOfferRequest(this.$route.query.token, selectOfferObj)
        .then(() => {
          this.$router.push({ name: this.redirectToLogin ? 'login' : 'registration-validation' })
        })
    },
  },
}
</script>

<style scoped></style>
