<template>
  <div>
    <app-disconnected-header />

    <b-nav
      id="navbarTabRegistration"
      :horizontal="true"
      class="justify-content-around pt-2"
    >
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="tab.name"
        :active="activeTabIndex === index"
        href="javascript:void(0)"
        link-classes="p-0"
      >

        <div
          class="text-nowrap"
          @click="switchTab(tab.component)"
        >

          <b-button :variant="tab.component === activeTab ? 'primary' : ''" class="nav-btn p-0">
            <span>{{ index + 1 }}</span>
          </b-button>
        </div>

      </b-nav-item>
    </b-nav>

    <div class="line" :style="line"></div>

    <b-card class="mb-0 mx-3 p-2" no-body>
      <keep-alive>
        <component
          :is="activeTab"
          ref="tabs"
          :selected-offer="selectedOffer"
          :selected-offer-id="Number(selectedOffer.id)"
          :setup-intent-id="setupIntent.id"
          :redirect-to-login="redirectToLogin"
          @onFilesUploaded="val => (saveLoader = !val)"
          @payment-done="paymentDone = true"

          @previous-tab="switchTab(tabs[activeTabIndex -1].component)"
          @next-tab="switchTab($event || tabs[activeTabIndex +1].component)"
          @selected-offer="getSelectedOffer($event)"
          @redirect-to-login="getRedirectToLogin($event)"
          @setup-intent="getSetupIntent($event)"
        />
      </keep-alive>
    </b-card>

  </div>
</template>

<script>
import { findIndex } from 'lodash'

import { mapActions } from 'vuex'
import AppDisconnectedHeader from '../../layouts/components/AppDisconnectedHeader.vue'

import Thanks from './tab/RegistrationThanks.vue'
import Payment from './tab/RegistrationPayment.vue'
import Cgv from './tab/RegistrationCgv.vue'
import Offers from './tab/RegistrationOffers.vue'

export default {
  name: 'RegistrationWizard',
  components: {
    Offers,
    Cgv,
    Payment,
    Thanks,
    AppDisconnectedHeader,
  },
  data() {
    return {
      saveLoader: false,
      currentTab: null,
      stepIndex: 0,
      selectedOffer: {
        id: null,
      },
      redirectToLogin: false,
      setupIntent: {
        id: null,
      },
      paymentDone: false,
      activeTabIndex: 0,
      tabs: [
        {
          title: this.$t('registration.offers.title'),
          component: 'offers',
        },
        {
          title: this.$t('registration.cgv.title'),
          component: 'cgv',
        },
        {
          title: this.$t('registration.payment.title'),
          component: 'payment',
        },
        {
          title: this.$t('registration.thanks'),
          component: 'thanks',
        },
      ],
      lineWidth: '75%',
      lineLeft: '12%',
    }
  },
  computed: {
    line() {
      return {
        'margin-left': this.lineLeft,
        width: this.lineWidth,
      }
    },
  },
  watch: {
    '$route.params.tab': {
      handler(tabName) {
        this.activeTab = tabName || 'offers'
        const activeTabIndex = findIndex(this.tabs, tab => tab.component === this.activeTab)

        if (activeTabIndex !== -1) this.activeTabIndex = activeTabIndex
      },
      immediate: true,
    },
    selectedOffer: {
      handler(offer) {
        if (offer.subscription.totalPriceIncludingTaxes < 1) {
          this.tabs = [
            {
              title: this.$t('registration.offers.title'),
              component: 'offers',
            },
            {
              title: this.$t('registration.cgv.title'),
              component: 'cgv',
            },
            {
              title: this.$t('registration.thanks'),
              component: 'thanks',
            },
          ]
        } else {
          this.tabs = [
            {
              title: this.$t('registration.offers.title'),
              component: 'offers',
            },
            {
              title: this.$t('registration.cgv.title'),
              component: 'cgv',
            },
            {
              title: this.$t('registration.payment.title'),
              component: 'payment',
            },
            {
              title: this.$t('registration.thanks'),
              component: 'thanks',
            },
          ]
        }
      },
    },
    tabs: {
      handler(tabs) {
        if (tabs.length < 4) {
          this.lineWidth = '67%'
          this.lineLeft = '16%'
        } else {
          this.lineWidth = '75%'
          this.lineLeft = '12%'
        }
      },
    },
  },
  mounted() {
    // Pour éviter tout problème de droits, on déconnecte l'utilisateur si il est connecté
    this.logout({ redirectLogin: false })
  },
  methods: {
    ...mapActions('auth', ['logout']),
    switchTab(componentName) {
      this.$router.push({
        ...this.$route,
        params: {
          tab: componentName,
        },
      })
    },
    formSubmitted() {
      this.formSuccess = false
    },
    getSelectedOffer(selectedOffer) {
      this.selectedOffer = selectedOffer
    },
    getRedirectToLogin(redirectToLogin) {
      this.redirectToLogin = redirectToLogin
    },
    getSetupIntent(setupIntent) {
      this.setupIntent = setupIntent
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.tabs_service-add .nav-tabs {
  align-items: flex-end;
}

#navbarTabRegistration {
  .nav-item {
    padding: 8px;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-right: 0;
      flex-wrap: nowrap;
      pointer-events: none !important;
      cursor: none;

      .nav-btn {
        border: 1px solid black !important;
        border-radius: 100%;
        width: 2.71rem;
        height: 2.71rem;
        color: white !important;
        background-color: #43A047 !important;
      }
    }

    .nav-link {
      .btn-secondary {
        border: none;
        color: black !important;
        background-color: white !important;
      }
    }

    .nav-link.active::after {
      transform: none;
      background: none !important;
    }

  }
}

.line {
  height: 1px;
  background-color: black;
  position: relative;
  bottom: 28px;
  z-index: -1;
}

.nav-tabs .line {
  bottom: 30px;
}

[dir] body.dark-layout #navbarTabRegistration {
  .nav-item {
    border-color: black !important;

    .nav-link {
      .nav-btn {
        color: white !important;
      }
    }

    .nav-link {
      .btn-secondary {
        background-color: black !important;
      }
    }
  }
}

</style>
