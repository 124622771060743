import { render, staticRenderFns } from "./RegistrationOffers.vue?vue&type=template&id=ac68c3c8&scoped=true&"
import script from "./RegistrationOffers.vue?vue&type=script&lang=js&"
export * from "./RegistrationOffers.vue?vue&type=script&lang=js&"
import style0 from "./RegistrationOffers.vue?vue&type=style&index=0&id=ac68c3c8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac68c3c8",
  null
  
)

export default component.exports