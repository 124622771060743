<template>
  <section>
    <div class="col-sm-12 px-1">
      <!-- pricing plan cards -->
      <validation-observer ref="mainInformationsAccountRules" tag="form" @submit.prevent="validationForm">
        <b-row class="pricing-card">
          <b-col class="mx-auto" lg="10" md="12" offset-lg="2" offset-sm-2 sm="10">
            <b-row class="match-height">
              <b-col v-for="offer in offers" :key="offer.id" md="4" sm="6">
                <b-card align="center" class="popular">
                  <!-- Offer Details -->
                  <div class="item-img text-center py-1">
                    <img v-if="offer.iconFileUrl" class="align-middle" height="40px" :src="offer.iconFileUrl">
                    <feather-icon v-else class="align-middle" icon="SendIcon" size="40" />
                  </div>
                  <div class="item-img text-center pt-0">
                    <h4>
                      {{ offer.reference | trans }}
                    </h4>
                    <p>
                      {{ offer.subscription.totalDiscountedPrice | priceFormat }}
                      {{ $t('common.excluding_taxes.abbreviation') }}
                      {{ $t(`registration.offers.${offer.subscription.paymentFrequency}`) }}
                    </p>
                    <p>
                      {{
                        offer.subscription.totalDiscountedPrice * (1 + offer.subscription.vatRate / 100) | priceFormat
                      }}
                      {{ $t('common.including_taxes.abbreviation') }}
                      {{ $t(`registration.offers.${offer.subscription.paymentFrequency}`) }}
                    </p>
                    <p>
                      {{ $t('registration.offers.duration') }} :
                      {{ $t(`registration.offers.${offer.duration}`) }}
                    </p>
                  </div>
                  <!-- <div>
                    {{ offer.description | trans }}
                  </div> -->
                  <!-- TODO VHTML check if ok -->
                  <div class="v-html-offer-description" v-html="offer.description[lang]" />
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item v-for="description in offer.subscription.descriptions" :key="description.id">
                      {{ description.description | trans }}
                    </b-list-group-item>
                  </b-list-group>

                  <!-- Product Actions -->
                  <div class="item-options text-center mt-2">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('registration.offers.choose')"
                      class="validation-required"
                      rules="required"
                    >
                      <b-button class="btn-cart" variant="outline-primary" @click="selectOffer(offer)">
                        <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                        <span>{{ $t('registration.offers.choose') }}</span>
                      </b-button>
                    </validation-provider>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </div>

  </section>
</template>
<script>
import { fetchRegisterSelectOfferRequest } from '@/request/globalApi/requests/registerRequests'
import number from '@/mixins/number'
import { mapState } from 'vuex'

export default {
  name: 'RegistrationOffers',
  mixins: [number],
  data() {
    return {
      offers: {},
      selectedOffer: null,
      nextRedirection: false,
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
  },
  mounted() {
    this.APIFetchOffers()
    this.selectedOffer = null
  },
  methods: {
    async APIFetchOffers() {
      fetchRegisterSelectOfferRequest(this.$route.query.token)
        .then(response => {
          const { offers } = response.data
          this.offers = offers
          this.$emit('redirect-to-login', response.data.redirectToLogin)
        })
        .catch(() => {
          this.toaster(this.$t('alert.registration.no_token_found'), 'danger', 'AlertTriangleIcon')
        })
    },
    selectOffer(offer) {
      this.selectedOffer = offer
      this.nextRedirection = true
      this.validationForm()
    },
    validationForm() {
      if (this.selectedOffer) {
        if (this.nextRedirection) {
          this.$emit('selected-offer', this.selectedOffer)
          this.$emit('next-tab')
          this.nextRedirection = false
        }

        this.selectedOffer = null
        return true
      }
      this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      return false
    },
  },
}
</script>
<style scoped>
@import '~@core/scss/base/pages/app-registration-offers.scss';
</style>
